//HEADER
.header () {
  background: @header-main;
  position: relative;
  #logo {
    float: left;
    img {
      max-width: 100%;
    }
  }
  .lang-selector {
    float: right;
    background: rgba(15, 15, 15, 0.3);
    border-radius: 3px;
    font-size: 0.7em;
    text-transform: uppercase;
    cursor: pointer;
    margin-left: 10px;
    margin-top: 12px;
    position: relative;
    a {
      color: @white;
      display: block;
      padding: 3px 10px;
    }
  }
  #languages {
    position: absolute;
    top: 100%;
    right: 0px;
    display: none;
    background: rgba(15, 15, 15, 0.3);
    border-radius: 3px;
    color: @white;
    font-size: 1em;
    text-transform: uppercase;
    padding: 3px 10px;
    text-align: right;
    z-index: 100;
    min-width: 100%;
    a {
      color: @white;
    }
  }
}
