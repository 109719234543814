//TEXT PAGE
.text-page {
  background: @white;
  padding: 20px;
  max-width: 650px;
  margin: 0 auto;
  h1 {
    text-align: center;
    font-size: 2em;
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  h2 {
    text-align: center;
    font-weight: 400;
    margin-bottom: 20px;
  }
  p {
    font-size: 0.875em;
    margin-bottom: 1.6em;
  }
  ul {
    margin-left: 2em;
  }
}
