//WEBFONTS
@import url(https://fonts.googleapis.com/css?family=Lato:400,700,300);
@import "font-awesome.min.css";
@import "../../resources/brands/brand";
//GRID
@import "lib/grid.less";

@column-width: 60;
@gutter-width: 0;
@columns: 12;
@total-width: 100%;

//color variables
@white: #fff;
@darkgrey: #989898;
@grey: #bebdbd;

//@primary-color: #f38931;
@facebook-color: #3b5998;
@twitter-color: #00aced;
@google-color: #c32f10;
@danger-color: #C40E0E;
@success-color: #2ecc71;

//media query variables
@xlarge: ~"only screen and (max-width: 1200px)";
@large: ~"only screen and (max-width: 920px)";
@medium: ~"only screen and (max-width: 768px)";
@small: ~"only screen and (max-width: 580px)";
@xsmall: ~"only screen and (max-width: 420px)";

//GLOBAL
*,
*:after,
*:before {
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
}

html, body {
  height: 100%;
}

body {
  color: @dark;
  font: 400 100%/1.6 'Lato', Helvetica, Arial, sans-serif;
  background: @dark;
  @media @medium {
    font-size: 85%;
  }
}

.wrapper {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 15px;
}

a {
  color: @primary-color;
  text-decoration: none;
}

a:hover {
  color: darken(@primary-color, 10%);
}

button {

}

button, a.button {
  background: @primary-color;
  color: @white;
  display: inline-block;
  text-transform: uppercase;
  text-align: center;
  border-radius: 4px;
  font-size: 1em;
  padding: 8px 25px;
  margin: 10px 0 20px;
  border: 0 none;
  cursor: pointer;
}

a.button {
  &:hover {
    background: darken(@primary-color, 10%);
  }
  &.large {
    font-size: 1.3em;
    padding: 12px 40px;
  }
}

button, .button, input[type=submit] {
  &.facebook, &.google, &.twitter {
    font-size: 1em;
    .fa {
      margin: 0 5px;
    }
  }
  &.facebook {
    background: @facebook-color;
    &:hover {
      background: darken(@facebook-color, 10%);
    }
  }
  &.google {
    background: @google-color;
    &:hover {
      background: darken(@google-color, 10%);
    }
  }
  &.twitter {
    background: @twitter-color;
    &:hover {
      background: darken(@twitter-color, 10%);
    }
  }
  &.danger {
    background: @danger-color;
    &:hover {
      background: darken(@danger-color, 10%);
    }
  }
  &.success {
    background: @success-color;
    &:hover {
      background: darken(@success-color, 10%);
    }
  }
  &.warning {

  }
}

input, select, textarea {
  font-family: 'Lato', Helvetica, Arial, sans-serif;
}

img {
  max-width: 100%;
}

.pull-right {
  float: right;
}

.pull-left {
  float: left;
}

.clear {
  clear: both;
}

.messages {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 130;
}

.alert {
  padding: 13px;
  color: @white;
  margin-bottom: 15px;
  border-radius: 4px;
  p {
    font-size: 0.875em;
    a {
      text-decoration: underline;
      color: @white;
    }
  }
  &.alert-error {
    background: @danger-color;
    border: 1px solid darken(@danger-color, 10%);
  }
  &.alert-success {
    background: @success-color;
    border: 1px solid darken(@success-color, 10%);
  }
  &.hide-after-load {
    animation: animation-hide 0s ease-in 10s forwards;
    animation-fill-mode: forwards;
  }
}

@keyframes animation-hide {
  to {
    width: 0;
    height: 0;
    overflow: hidden;
    opacity: 0;
  }
}

.success-label {
  color: @success-color;
}

.failure-label {
  color: @danger-color;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.form {
  background: @white;
  padding: 20px;
  h1 {
    text-align: center;
    font-size: 2em;
    font-weight: 300;
    margin-bottom: 10px;
    margin-top: 10px;
  }
  h2 {
    text-align: center;
    font-weight: 400;
  }
  .form-group {
    padding: 30px 30px 10px;
  }
}

.form-group {
  margin: 0 auto;
  border-top: 1px solid lighten(@grey, 10%);
  label, input[type="text"], input[type="password"], input[type="submit"], select, select option {
    display: block;

  }
  label {
    font-size: 0.75em;
    font-weight: 700;
    margin-top: 15px;
    margin-bottom: 2px;
    &.terms, &.radio {
      display: inline-block;
      font-weight: 400;
    }
    &.radio {
      font-size: 1em;
    }
  }
  input[type="text"], input[type="password"], select, select option {
    width: 100%;
    padding: 10px;
    font-size: 1em;
    border: 1px solid lighten(@grey, 10%);
    border-radius: 4px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    &.error {
      border: 1px solid @danger-color;
      border: 1px solid @danger-color;
      &:focus {
        border: 1px solid lighten(@grey, 10%);
      }
    }
  }
  select {
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAh0lEQVQ4T93TMQrCUAzG8V9x8QziiYSuXdzFC7h4AcELOPQAdXYovZCHEATlgQV5GFTe1ozJlz/kS1IpjKqw3wQBVyy++JI0y1GTe7DCBbMAckeNIQKk/BanALBB+16LtnDELoMcsM/BESDlz2heDR3WePwKSLo5eoxz3z6NNcFD+vu3ij14Aqz/DxGbKB7CAAAAAElFTkSuQmCC) no-repeat center right;
  }
  .error-msg {
    font-size: 0.75em;
    color: @danger-color;
  }
  #submit {
    background: @primary-color;
    color: @white;
    text-transform: uppercase;
    text-align: center;
    border-radius: 4px;
    font-size: 1.25em;
    padding: 12px 45px;
    margin: 20px 0 30px;
    border: 0 none;
    cursor: pointer;
    &:hover {
      background: darken(@primary-color, 10%);
    }
  }
  p {
    font-size: 0.875em;
    margin-top: 10px;
  }
  a.button {
    display: inline-block;
  }
  &.or {
    margin-top: 20px;
    padding: 20px 30px 10px;
    text-align: center;
    position: relative;
    &:after {
      background: @white;
      position: absolute;
      top: -27px;
      left: 50%;
      content: "OR";
      font-weight: 300;
      font-size: 2em;
      width: 80px;
      text-align: center;
      margin-left: -40px;
    }
  }
  &.social-buttons {
    padding-left: 0;
    padding-right: 0;
    .button {
      padding: 12px;
      white-space: nowrap;
    }
  }
}

.form-page {
  max-width: 450px;
  margin: 0 auto;
  .clearfix();
}

//MAIN
#content {
  background: url(../img/bg.jpg) no-repeat center center;
  background-size: cover;
  background-size: cover;
  padding: 60px 0;
}

@import "header.less";
@import "footer.less";

@import "login.less";
@import "signup.less";
@import "account.less";
@import "text.less";
