//SIGN UP PAGE
.signup-page {
  .clearfix();
  background: lighten(@grey, 20%);
  .info {
    .column(4);
    padding: 30px;
    h2 {
      font-size: 1.25em;
      margin-bottom: 10px;
    }
    ul {
      list-style: none;
      margin-bottom: 30px;
      li {
        margin-bottom: 10px;
        font-size: 1.125em;
        i {
          color: @primary-color;
          margin-right: 5px;
        }
      }
    }
    img {
      margin-top: 20px;
      &.devices {
        margin-top: 50px;
      }
    }
  }

  .form {
    .column(8);
    .form-group {
      max-width: 600px;
      margin: 30px auto 0px;
    }
  }
}
