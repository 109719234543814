//ACCOUNT PAGE
.account-page {
  .clearfix();
  background: @dark;
  .user {
    .column(3);
    color: @white;
    @media @large {
      .column(12);
    }
    .avatar {
      margin: 30px 20px 20px 30px;
      border-radius: 100%;
      border: 3px solid lighten(@dark, 30%);
      float: left;
      @media @large {
        width: 50px;
        margin: 10px 10px 10px 10px;
      }
    }
    h2 {
      float: left;
      font-size: 1.25em;
      margin-bottom: 10px;
      margin-top: 65px;
      @media @large {
        font-size: 1em;
        margin-top: 22px;
      }
    }
    #toggleMenu {
      display: none;
      color: @white;
      float: right;
      padding: 2px 10px 3px;
      border: 1px solid lighten(@dark, 20%);
      margin: 20px 15px 10px;
      border-radius: 2px;
      @media @large {
        display: inline-block;
      }
    }
    .menu {
      @media @large {
        display: none;
      }
    }
    ul {
      border-top: 1px solid lighten(@dark, 10%);
      list-style: none;
      margin-bottom: 30px;
      @media @large {
        margin-bottom: 0px;
      }
      li {
        font-size: 1.125em;
        border-bottom: 1px solid lighten(@dark, 10%);
        a {
          color: @white;
          padding: 7px 30px 7px;
          display: block;
          font-size: 0.875em;
          &:hover {
            color: @primary-color;
          }
          &.active {
            color: @primary-color;
          }
        }
        span {
          width: 30px;
          display: inline-block;
          text-align: center;
        }
        i {
          margin-right: 10px;
        }
      }
    }
  }
  .account-content {
    background: @white;
    .column(9);
    padding: 20px;
    min-height: 480px;
    @media @large {
      .column(12);
    }
    h1 {
      font-size: 2em;
      font-weight: 300;
      margin-bottom: 10px;
      margin-top: 10px;
    }
    h2 {
      font-weight: 400;
    }
    h3 {
      margin-bottom: 10px;
      font-size: 0.875em;
      margin-top: 10px;
    }
    .left, .right {
      .column(6);
      @media @large {
        .column(12);
      }
    }
    .left .form-group {
      padding-right: 20px;
      @media @large {
        padding-right: 0;
      }
    }
    .right .form-group {
      padding-left: 20px;
      @media @large {
        padding-left: 0;
        border-top: 0 none;
      }
    }
    .form-group {
      &.last {
        border: 0 none;
      }
      &.full {
        input[type="text"], input[type="password"], select, select option {
          max-width: 50%;
        }
      }
    }
    #submit.delete {
      background: @danger-color;
    }
    .half {
      width: 50%;
      padding-right: 10px;
      float: left;
      margin-bottom: 15px;
      &.last {
        padding-right: 0;
        padding-left: 10px;
      }
    }
    .account-table {
      width: 100%;
      margin-bottom: 20px;
      @media @large {
        font-size: 0.9em;
      }
      thead {
        background: @dark;
        color: @white;
      }
      th {
        font-weight: 700;
        border: 1px solid @dark;
      }
      td {
        font-size: 0.875em;
        border: 1px solid @grey;
        vertical-align: middle;
      }
      th, td {
        text-align: center;
        padding: 10px 5px;
      }
      tr:nth-of-type(even) td {
        background: lighten(@grey, 20%);
      }
      .view {
        background: @primary-color;
        border-radius: 20px;
        color: @white;
        font-size: 0.875em;
        padding: 4px 10px;
        white-space: nowrap;
        //display: inline-block;
      }
    }
    .dashboard {
      h1 {
        border-bottom: 1px solid lighten(@grey, 10%);
      }
      .subtitle {
        margin-top: 10px;
        font-size: 1.25em;
      }
      .left {
        padding-right: 10px;
      }
      .right {
        padding-left: 10px;
      }
      @media @large {
        .left, .right {
          padding: 0;
        }
      }
      p {
        margin-top: 8px;
      }
      div.help {
        width: 100%;
        background: url(../img/arrow.jpg) no-repeat 110px 10px;
      }
      h2.help {
        margin-top: 20px;
        font-weight: bold;
        font-size: 1.6em;
        margin-bottom: 10px;
      }
      p.help {
        max-width: 450px;
        margin: 0 auto;
        margin-bottom: 20px;
      }
      .summary {
        width: 100%;
        margin: 15px 0;
        border-collapse: collapse;
        td, th {
          padding: 5px 8px;
        }
        thead th {
          background: @dark;
          color: #FFFFFF;
          font-size: 12px;
          font-weight: bold;
        }
        thead th:first-child {
          border: none;
        }
        tbody td {
          border-left: 1px solid darken(@white, 10%);
          font-size: 12px;
          font-weight: normal;
        }
        tbody tr:nth-of-type(even) td {
          background: darken(@white, 4%);
        }
        tbody td:first-child {
          border-left: none;
        }
        tbody tr:last-child td {
          border-bottom: none;
        }
      }
    }
    .subscriptions {
      .activate {
        display: flex;
        a {
          text-align: center;
          background: @primary-color;
          color: @white;
          border-radius: 3px;
          padding: 5px 15px;
          &:hover {
            background: darken(@primary-color, 10%);
          }
        }
        &.not-active {
          justify-content: center;
          a {
            font-size: 2rem;
          }
        }
      }
    }
    .devices {
      .add {
        display: flex;
        a {
          text-align: center;
          background: @primary-color;
          color: @white;
          border-radius: 3px;
          padding: 5px 15px;
          &:hover {
            background: darken(@primary-color, 10%);
          }
        }
      }
    }
  }
}
